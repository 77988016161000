// FIREBASE_CONFIG.js
export const firebaseConfig = {
    apiKey: "AIzaSyDN8o_Hi722jdJdEvJTSKxpot_RIrl4Vu4",
    authDomain: "ipunch-d5b5a.firebaseapp.com",
    databaseURL: "https://ipunch-d5b5a-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "ipunch-d5b5a",
    storageBucket: "ipunch-d5b5a.appspot.com",
    messagingSenderId: "825666422457",
    appId: "1:825666422457:web:cd6e65b53824c53535fd68",
    measurementId: "G-ZWW3S5260M"
  };